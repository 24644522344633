import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  ListGroup,
  Image,
  Carousel,
  Badge,
} from "react-bootstrap";
import {
  FaTag,
  FaHome,
  FaCar,
  FaBed,
  FaShower,
  FaExpand,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaClock,
  FaEye,
  FaFlag 
} from "react-icons/fa";
import waterwheel from "../../Assests/waterwheel-street-markham-ontario-4.webp";
import thumbnil from "../../Assests/waterwheel-street-markham-ontario-4-150x120.webp";

function PropertyDetails() {
  const [activeIndex, setActiveIndex] = useState(0);
  const mainImages = [
    waterwheel,
    waterwheel,
    waterwheel,
    waterwheel,
    waterwheel,
  ];
  const thumbnailImages = [thumbnil, thumbnil, thumbnil, thumbnil, thumbnil];

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="bg__properties__color py-5">
      <Container>
        <Row className="mb-4">
          <Col>
            <div className="d-flex align-items-center mb-2">
              <Badge bg="warning" text="dark" className="me-2 property__new__items">
                For Buy
              </Badge>
              <Badge bg="danger" className="property__new__items">New</Badge>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2>4 Waterwheel Street</h2>
                <p>Markham, Raymerville, Raymerville</p>
              </div>

              {/* Price section aligned to the right */}
              <h3 className="text-warning mb-0">$1,688,000 (Fixed)</h3>
            </div>

            <div className="d-flex align-items-center">
              <span>
                <FaClock /> 2 days ago
              </span>
              <span className="ms-3">
                <FaEye /> Views: 7
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <Card style={{ backgroundColor: "transparent", border: "0" }}>
              <Carousel
                activeIndex={activeIndex}
                onSelect={handleSelect}
                indicators={false}
              >
                {mainImages.map((image, index) => (
                  <Carousel.Item key={index}>
                    <Image
                      src={image}
                      alt={`Property Image ${index + 1}`}
                      fluid
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <Card.Body style={{ padding: "10px 0 0 0" }}>
                <div className="d-flex justify-content-between">
                  {thumbnailImages.map((thumbnail, index) => (
                    <Image
                      key={index}
                      src={thumbnail}
                      alt={`Thumbnail ${index + 1}`}
                      thumbnail
                      onClick={() => setActiveIndex(index)}
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Body>
                <h5>Contact Admin</h5>
                <ListGroup variant="flush" className="mb-4">
                  <ListGroup.Item>Phone: +1 (647) 987-6XXX</ListGroup.Item>
                  <ListGroup.Item>WhatsApp: +1 (647) 987-6XXX</ListGroup.Item>
                </ListGroup>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Name" required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email" required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="tel" placeholder="Phone" required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      required
                    />
                  </Form.Group>
                  <Button variant="warning" type="submit">
                    Send Message
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={8}>
            <div className="bg-white p-3">
              <h4>Overview</h4>
              <Row className="g-3 mb-4">
                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaTag size={22} className="icon__center__properties" />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">ID No</p>
                      <h5>19689</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaHome size={22} className="icon__center__properties" />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Type</p>
                      <h5>House</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaBed size={22} className="icon__center__properties" />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Bedroom</p>
                      <h5>5</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaShower
                        size={22}
                        className="icon__center__properties"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Bath</p>
                      <h5>6</h5>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="g-3 mb-4">
                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaExpand
                        size={22}
                        className="icon__center__properties"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Sqft</p>
                      <h5>3000</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaMapMarkerAlt
                        size={22}
                        className="icon__center__properties"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Location</p>
                      <h5>Markham</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaCalendarAlt
                        size={22}
                        className="icon__center__properties"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Listed On</p>
                      <h5>14/10/2024</h5>
                    </div>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaCar size={22} className="icon__center__properties" />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Parking</p>
                      <h5>Yes</h5>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="g-3">
                <Col md={3}>
                  <div className="d-flex align-items-center">
                    <div className="icon__container__properties">
                      <FaFlag  size={22} className="icon__center__properties" />
                    </div>
                    <div className="ms-2">
                      <p className="mb-0">Purpose</p>
                      <h5>For Sell</h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-3">
              <div className="item__heading__properties">
                <h2>About This Listings</h2>
              </div>
              <div className="items__subheading__properties">
                <h3>Description</h3>
                <p>
                  Exceptional Gem in Sought-After Raymerville! Welcome to this
                  beautiful home Recently upgraded from top to bottom, this
                  residence features a newly renovated from top to bottom (2024)
                  kitchen with quartz counter top, an open-concept layout,
                  upgraded cabinetry. Enjoy new potlights throughout and
                  gleaming hardwood floors. The main floor offers a functional
                  layout Bright and airy, 5 spacious bedrooms on sec floor, 3
                  add bds 2 full 4pc bath in basement and kitchen rough-in. Step
                  outside to your private oasis featuring an inground pool,
                  Steps to Markville High School, parks, shopping mall, GO
                  Train, community center, public transit, and all amenities.
                  Dont miss the opportunity to own one of the best houses in
                  Raymerville, offering both luxury and convenience! *Legal
                  basement* – rough in ready for potential 2nd kitchen. Pool can
                  be removed at owner’s expense. S/S fridge, rangehood, stove,
                  dishwasher, CAC, sumsung washer/dryer, furnace & tankless hot
                  water tank 2022, insolation 2022, AC 5 years
                </p>
              </div>
              <div className="items__subheading__properties">
                <h2>About 4 Waterwheel Street, Markham</h2>
                <p>
                  4 Waterwheel Street, Markham, is the address you’ve been
                  dreaming of. This detached 2-storey brick house is a true gem
                  in the sought-after Raymerville community. As you step inside,
                  you’ll be greeted by a recently renovated kitchen with quartz
                  countertops, upgraded cabinetry, and new potlights that
                  illuminate the gleaming hardwood floors. The open-concept
                  layout on the main floor offers a bright and airy space,
                  perfect for both relaxing and entertaining.
                </p>
                <p>
                  With 5 spacious bedrooms on the second floor, this house
                  provides ample space for a growing family or guests.
                  Additionally, there are 3 additional bedrooms and 2 full 4pc
                  bathrooms in the finished basement, along with a kitchen
                  rough-in for added convenience. The house boasts a total of 10
                  rooms, ensuring that there is plenty of space for everyone to
                  enjoy.
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <h4>Latest Listings</h4>
            <Card>
              <Card.Img
                variant="top"
                src="https://via.placeholder.com/300x200"
              />
              <Card.Body>
                <Card.Text>
                  Listing description goes here for another property...
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PropertyDetails;
