import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  ListGroup,
  Image,
  Carousel,
  Badge,
} from "react-bootstrap";
import {
  FaTag,
  FaHome,
  FaCar,
  FaBed,
  FaShower,
  FaExpand,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaClock,
  FaEye,
  FaFlag
} from "react-icons/fa";
import waterwheel from "../../Assests/waterwheel-street-markham-ontario-4.webp";
import thumbnil from "../../Assests/waterwheel-street-markham-ontario-4-150x120.webp";

const Properties = () => {
  const [data, setPropertyData] = useState(null); // State to store fetched property data
  const [loading, setLoading] = useState(true); // State to show loading state
  const [error, setError] = useState(null); // State to handle errors

 

  const [activeIndex, setActiveIndex] = useState(0);
  const mainImages = [
    waterwheel,
    waterwheel,
    waterwheel,
    waterwheel,
    waterwheel,
  ];
  const thumbnailImages = [thumbnil, thumbnil, thumbnil, thumbnil, thumbnil];

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  // Fetch data from API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch('http://localhost:8081/api/odata/Property?$top=1', {
                headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW5kb3IvdHJyZWIvNjI5MiIsImF1ZCI6IkFtcFVzZXJzUHJkIiwicm9sZXMiOlsiQW1wVmVuZG9yIl0sImlzcyI6InByb2QuYW1wcmUuY2EiLCJleHAiOjI1MzQwMjMwMDc5OSwiaWF0IjoxNzI4OTcyNjMzLCJzdWJqZWN0VHlwZSI6InZlbmRvciIsInN1YmplY3RLZXkiOiI2MjkyIiwianRpIjoiODQ3NTJlZTljNjY0MTQxZCIsImN1c3RvbWVyTmFtZSI6InRycmViIn0.ONBoLBhod6XnmgGtoXaypodtECSd83RRZw5vLU8bGJI' // Replace with your actual token
                }
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const result = await response.json();
            setPropertyData(result.value[0]); // Set the fetched data in state (using first property for this example)
        } catch (err) {
            setError(err.message); // Set error message in case of failure
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    };

    fetchData();
}, []);

// Render loading state
if (loading) return <p>Loading...</p>;

// Render error message
if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Property Details</h1>
      {data ? (
        <div className="bg__properties__color py-5">
          <Container>
            <Row className="mb-4">
              <Col>
                <div className="d-flex align-items-center mb-2">
                  <Badge bg="warning" text="dark" className="me-2 property__new__items">
                    For Buy
                  </Badge>
                  <Badge bg="danger" className="property__new__items">New</Badge>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h2>{data.PropertyType}</h2>
                    <p>{data.UnparsedAddress}</p>
                  </div>

                  <h3 className="text-warning mb-0">${data.ListPrice} (Fixed)</h3>
                </div>

                <div className="d-flex align-items-center">
                  <span>
                    <FaClock /> 2 days ago
                  </span>
                  <span className="ms-3">
                    <FaEye /> Views: 7
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Card style={{ backgroundColor: "transparent", border: "0" }}>
                  <Carousel
                    activeIndex={activeIndex}
                    onSelect={handleSelect}
                    indicators={false}
                  >
                    {mainImages.map((image, index) => (
                      <Carousel.Item key={index}>
                        <Image
                          src={image}
                          alt={`Property Image ${index + 1}`}
                          fluid
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Card.Body style={{ padding: "10px 0 0 0" }}>
                    <div className="d-flex justify-content-between">
                      {thumbnailImages.map((thumbnail, index) => (
                        <Image
                          key={index}
                          src={thumbnail}
                          alt={`Thumbnail ${index + 1}`}
                          thumbnail
                          onClick={() => setActiveIndex(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <Card.Body>
                    <h5>Contact Admin</h5>
                    <ListGroup variant="flush" className="mb-4">
                      <ListGroup.Item>Phone: +1 (647) 987-6XXX</ListGroup.Item>
                      <ListGroup.Item>WhatsApp: +1 (647) 987-6XXX</ListGroup.Item>
                    </ListGroup>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Name" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control type="email" placeholder="Email" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control type="tel" placeholder="Phone" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Message"
                          required
                        />
                      </Form.Group>
                      <Button variant="warning" type="submit">
                        Send Message
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={8}>
                <div className="bg-white p-3">
                  <h4>Overview</h4>
                  <Row className="g-3 mb-4">
                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaTag size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">ID No</p>
                          <h5>19689</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaHome size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Type</p>
                          <h5>{data.PropertyType}</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaBed size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Bedroom</p>
                          <h5>{data.BedroomsTotal}</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaShower size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Bath</p>
                          <h5>{data.BathroomsTotalInteger}</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-3 mb-4">
                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaExpand size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Sqft</p>
                          <h5>{data.SquareFootSource}</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaMapMarkerAlt size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Location</p>
                          <h5>Markham</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaCalendarAlt size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Listed On</p>
                          <h5>14/10/2024</h5>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <div className="icon__container__properties">
                          <FaFlag size={22} className="icon__center__properties" />
                        </div>
                        <div className="ms-2">
                          <p className="mb-0">Property Code</p>
                          <h5>PP000985</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div>No property details available</div>
      )}
    </div>
  );
};

export default Properties;
