import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Use Routes instead of Switch
import Properties from "./Components/Pages/Properties/Properties";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropertyDetails from "./Components/Pages/Properties/PropertyDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/properties" element={<Properties />} />
        <Route path="/property_details" element={<PropertyDetails />} />

        <Route path="/" element={<h1>Welcome to the Home Page</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
